<template>
  <a-select
    allowClear
    :placeholder="_selfPlaceholder"
    :disabled="_selfDisabled"
    v-model="selectedPayment"
    @change="selectPayment"
  >
    <!-- <a-select-option value="">{{ _selfPlaceholder }}</a-select-option> -->
    <a-select-option v-for="item in PaymentList" :value="item.payId" :key="item.payId">
      {{ item.payName }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  mounted() {
    this.fetchPaymentList()
  },
  props: ['partyId', 'placeholder', 'disabled', 'defaultPayment'],
  data() {
    return {
      PaymentList: [],
      selectedPayment: undefined,
    }
  },
  computed: {
    _selfPlaceholder() {
      return this.placeholder || this.$t(`--请选择支付方式--`)
    },
    _selfDisabled() {
      return this.disabled || false
    },
  },
  methods: {
    fetchPaymentList() {
      const _self = this
      _self.PaymentList = [
        {
          createTime: '2017-06-06 10:38:07',
          creator: {
            id: -1,
          },
          lastUpdateTime: '2017-06-06 10:38:07',
          payCode: 'onlinepay',
          payId: 1,
          payName: '在线支付',
          status: 'NORMAL',
          updator: {
            id: -1,
          },
        },
        {
          createTime: '2020-08-24 15:44:38',
          creator: {
            id: -1,
          },
          lastUpdateTime: '2020-08-24 15:44:38',
          payCode: 'cod',
          payId: 2,
          payName: '货到付款',
          status: 'NORMAL',
          updator: {
            id: -1,
          },
        },
      ]
      if (_self.defaultPayment != undefined) {
        _self.selectedPayment = _self.defaultPayment
      }
    },
    selectPayment() {
      if (this.selectedPayment != undefined) {
        this.$emit('selected', {
          data: this.selectedPayment,
        })
      }
    },
  },
  watch: {
    defaultPayment(val) {
      this.selectedPayment = val
    },
  },
}
</script>
