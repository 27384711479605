<template>
  <a-select
    allowClear
    :disabled="disabled"
    v-model="selectedShipment"
    :placeholder="_selfPlaceholder"
    :not-found-content="loading ? undefined : null"
    @focus="fetchShipmentList"
    @change="selectShipment"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option
      v-for="item in shipmentList"
      :value="item.uniqueCode"
      :key="item.uniqueCode"
      :option="item"
    >
      {{ item.accountName }}
    </a-select-option>
  </a-select>
</template>

<script>
import http from '../service/axios'
import api from '../service/api'

export default {
  props: [
    'placeholder',
    'disabled',
    'wmsAppCode',
    'warehouseCode',
    'defaultShippingCode',
    'defaultShippingName',
    'shopCode',
    'platform',
  ],
  data() {
    return {
      shipmentList: [],
      selectedShipment: undefined,
      selectedShipmentData: {},
      loading: false,
    }
  },
  computed: {
    _selfPlaceholder() {
      return this.placeholder || `${this.$t('请选择快递')} `
    },
  },
  methods: {
    fetchShipmentList() {
      if (this.shipmentList.length) return
      this.loading = true
      http({
        url: api.getShippingListByShopAndWarehouse,
        type: 'post',
        data: {
          wmsAppCode: this.wmsAppCode,
          warehouseCode: this.warehouseCode,
          shopCode: this.shopCode,
          platform: this.platform,
          merchantCode: localStorage.getItem('merchantCode'),
        },
        success: (res) => {
          this.loading = false
          let list = res.result
          if (this.defaultShippingCode) {
            this.selectedShipment = this.defaultShippingCode
          } else if (this.defaultShippingName) {
            this.selectedShipment = this.defaultShippingName
          }
          this.shipmentList = list
        },
        fail: (res) => {
          console.log(res)
        },
      })
    },
    selectShipment(value, option) {
      let choosedData = option?.data?.attrs?.option
      if (this.selectedShipment != undefined) {
        this.$emit('selected', {
          shippingCode: choosedData.shippingCode,
          shippingAccountCode: value,
          data: choosedData,
        })
      }
    },
  },
  watch: {
    warehouseCode: {
      handler(val) {
        this.selectedShipment = undefined
        if (val) {
          this.shipmentList = []
          this.fetchShipmentList()
        }
      },
      immediate: true,
    },
    defaultShippingCode(val) {
      if (this.warehouseCode) {
        this.selectedShipment = val
      } else {
        this.selectedShipment = undefined
        this.$emit('selected', {})
      }
    },
  },
}
</script>
